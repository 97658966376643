@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500&family=Questrial&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Poppins'!important;
}

.navbar {
    background-color: transparent!important;
    position: absolute!important;
    box-shadow: none!important;
}

.icon {
    margin-top: -40px!important;
}

.filter {
    filter: brightness(80%);
}

.card-style {
    height: 340px;
    min-width: 200px;
}

.card-bg {
    background-color:lavenderblush!important;
}